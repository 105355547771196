<template>
    <Guest :title="t('register1')" logo>
        <div class="py-8">
            <form v-on:submit.prevent="navigateNext">
                <TextInput v-model:value="email" v-model:errors="errors.email" :label="t('email_label')" :placeholder="t('email_placeholder')" autofocus autocapitalize="none" />
                <TextInput v-model:value="password" v-model:errors="errors.password" :label="t('password_label')" :placeholder="t('password_placeholder')" password class="mt-4"/>
                <TextInput v-model:value="password_confirmation" v-model:errors="errors.password_confirmation" :label="t('password_confirmation_label')" :placeholder="t('password_confirmation_placeholder')" password class="mt-4"/>

                <div class="mt-8">
                    <button type="submit" class="button w-full">
                        {{ t('next_step') }}
                    </button>
                </div>
            </form>
        </div>
        <div class="mt-4 text-center">
            <router-link :to="{ name: 'login'}" class="w-full text-white font-medium text-sm">
                {{ t('i_have_an_account') }}
            </router-link>
        </div>
    </Guest>
</template>

<script>
import router from "@/router";
import Guest from "@/wrappers/Guest";
import TextInput from "@/components/forms/TextInput";
import { register_form_to_storage } from "@/helpers/storage/RegisterFormHelper";
import { register_validation } from "@/helpers/api/RegistrationHelper";
import { setLoading } from "@/helpers/storage/loadingSpinnerHelper";
import messages from "@/helpers/storage/MessageHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "Register",
    components:{
        Guest,
        TextInput
    },
    setup() {
        return useI18n({
            viewPrefix: "Register"
        });
    },
    computed:{
        email: register_form_to_storage('email'),
        password: register_form_to_storage('password'),
        password_confirmation: register_form_to_storage('password_confirmation'),
    },
    data(){
        return {
            errors: {
                email: [],
                password: [],
                password_confirmation: [],
            }
        }
    },
    methods: {
        navigateNext() {
            setLoading(true);

            const data = {
                email: this.email, 
                password: this.password, 
                password_confirmation: this.password_confirmation
            }

            register_validation(data)
                .then((result)=> {
                    if (result.success){
                        router.push({name: "register.2"});
                    } else {
                        this.errors = result.errors;
                    }
                })
                .catch((error) => {
                    messages.error(this.t('messages.something_went_wrong', {error: error.message}));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    },
}
</script>

<style scoped>

</style>
